<template>
  <v-navigation-drawer app :color="backgroundColor" dark permanent width="90">
    <v-list dense>
      <v-list-item-group>
        <v-list-item
          v-for="(item, index) in dataOption"
          :key="index"
          link
          @click="selectMenuItem(item, index)"
          :class="[{ 'is-active': activeIndex === index }]"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideBar',
  props: {
    dataOption: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0,
      backgroundColor: 'transparent'
    }
  },
  mounted() {
    // 页面渲染完成后，获取路由参数type并执行滚动操作
    const type = this.$route.query.type
    if (type) {
      setTimeout(() => {
        this.updateActiveIndex(type)
        this.gotoSite(type)
        console.log('页面更新完成')
      }, 2000)
    }
  },

  watch: {
    '$route.query.type': {
      immediate: true,
      handler(newType) {
        console.log('Route type changed:', newType)
        this.updateActiveIndex(newType)
        this.$nextTick(() => {
          this.gotoSite(newType) // 在 DOM 渲染完成后进行滚动
        })
      }
    }
  },
  methods: {
    updateActiveIndex(type) {
      const index = this.dataOption.findIndex((item) => item.name === type)
      if (index !== -1) {
        this.activeIndex = index
      }
    },
    gotoSite(type) {
      // Ensure the element with the id exists in the DOM
      const element = document.querySelector(`#${type}`)
      const mainContentContainer = document.querySelector(
        '.main-content-container'
      )

      if (element && mainContentContainer) {
        // Calculate scroll position
        const offset = 70 // Adjust offset if necessary
        const elementPosition = element.offsetTop
        console.log('Element position:', elementPosition) // Debugging position

        // Scroll the main container to the target element
        mainContentContainer.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth'
        })
      } else {
        // Log more details to help with debugging
        console.warn('Element not found for type:', type)
        if (!element) {
          console.warn(`Could not find element with id "${type}"`)
        }
        if (!mainContentContainer) {
          console.warn('Main content container not found')
        }
      }
    },
    selectMenuItem(item, index) {
      this.activeIndex = index
      this.gotoSite(item.name)
      this.$emit('menuItemSelected', item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.v-navigation-drawer {
  padding: 3px 0;
  position: relative !important;
  min-width: 90px;
  z-index: inherit;
  .v-list-item {
    padding: 3px 3px;
    color: rgb(105, 109, 145) !important;
    .v-list-item__title {
      font-size: 1rem !important;
      font-weight: 550 !important;
    }
    .v-list-item__content {
      padding: 0;
    }
  }
  .v-list-item.is-active {
    color: rgba(65, 209, 244) !important;
    background-color: transparent !important;
  }
  .v-list-item--active {
    background-color: transparent !important;
  }
  .v-list-item--link:before {
    background-color: transparent !important;
  }
  .v-navigation-drawer__border {
    background-color: transparent !important;
  }
}
</style>
