import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/vuetify' // Vuetify 配置文件
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/fonts/iconfont.css'
import '@/styles/main.css' // Tailwind CSS 文件

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import LazyLoad from '@/utils/lazy-load' //懒加载
import { Lazyload } from 'vant'
Vue.directive('lazy-load', LazyLoad)
Vue.use(Lazyload, {
  lazyComponent: true
})

// 注册 Vant 插件
Vue.use(Vant)

// 注册 Vuetify 插件
Vue.use(Vuetify)

Vue.config.productionTip = false

const vuetify = new Vuetify()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
