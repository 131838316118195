<template>
  <div class="game-card" @click.stop="openGame">
    <div class="card-content">
      <!-- 使用 IntersectionObserver 进行懒加载 -->
      <van-image
        v-if="shouldLoad"
        :src="image"
        :srcset="`${imageSmall} 320w, ${image} 800w`"
        sizes="(max-width: 600px) 320px, 800px"
        :class="[load ? 'is-loaded' : '']"
        class="game-image responsive-image w-full blur-image transition-opacity h-full object-cover"
        alt="Game Image"
        @load="handleImageLoad"
      />
    </div>
    <div class="text-content">
      <span class="titles">{{ title }}</span>
      <div class="subtitle">{{ subtitle }}</div>
    </div>

    <span
      v-if="badge"
      class="badge"
      :style="{ backgroundColor: getColor(badge) }"
    >
      {{ badge }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'GameCard',
  props: {
    image: String,
    imageSmall: String, // 添加低分辨率预览图像
    title: String,
    subtitle: String,
    badge: String,
    gameId: Number,
    badgeColor: {
      type: String,
      default: '#ff9800'
    }
  },
  data() {
    return {
      load: false,
      shouldLoad: false, // 用于控制是否加载图片
      observer: null
    }
  },
  mounted() {
    this.setupIntersectionObserver()
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    setupIntersectionObserver() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.shouldLoad = true
              this.observer.disconnect()
            }
          })
        })
        this.observer.observe(this.$el)
      } else {
        // 如果浏览器不支持 IntersectionObserver，直接加载图片
        this.shouldLoad = true
      }
    },
    handleImageLoad() {
      this.load = true
    },
    getColor(input) {
      const normalizedInput = input.toLowerCase()
      switch (normalizedInput) {
        case 'new':
          return '#0078d4'
        case 'hot':
          return '#f44336'
        default: {
          const colors = ['#ff4081', 'orange']
          return colors[Math.floor(Math.random() * colors.length)]
        }
      }
    },
    openGame() {
      this.$emit('openWebview', this.gameId)
    }
  }
}
</script>

<style lang="scss" scoped>
.game-card {
  display: inline-block;
  width: calc(33.33% - 10px);
  margin: 5px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.card-content {
  position: relative;
  padding-bottom: 3px;
  border-radius: 10px;
  overflow: hidden;
  height: 113px;
}

.game-image {
  width: 100%;
  aspect-ratio: 9 / 11;
  object-fit: cover;
  border-radius: 10px;
  filter: blur(10px);
  opacity: 0.5;
  transition: filter 0.5s ease, opacity 0.5s ease;
}
.van-image.is-loaded {
  filter: blur(0);
  opacity: 1;
}

/* 占位符样式 */
.placeholder {
  width: 100%;
  height: 100%;
  aspect-ratio: 9 / 11;
  background: #e0e0e0; /* 灰色背景作为占位符 */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.loading-text {
  color: #777;
  font-size: 14px;
}

.text-content {
  width: 100%;
  text-align: center;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0%;
  bottom: 2%;
  padding: 0 3px;
  padding-bottom: 3px;
  background-color: rgb(67 62 62 / 72%);
  border-radius: 0 0 5px 5px;
}

.subtitle {
  font-size: 14px;
  line-height: 17px;
  color: rgb(230, 231, 237);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titles {
  width: 100%;
  font-size: 0.9rem;
  color: rgb(251, 255, 47);
  transform: translate(-50%, 50%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  text-shadow: 2px 2px 1px rgb(0, 20, 255);
}

.badge {
  position: absolute;
  top: -5px;
  right: -3px;
  padding: 2.5px 6px;
  border-radius: 12px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
</style>
